var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.loading)?_c('Loading'):_c('div',[_c('v-card-text',{staticStyle:{"height":"calc(100% - 52px)"}},[_c('Fields',{attrs:{"schema":{
        name: {
          type: 'string',
          label: 'Nombre del rol',
          description: 'Debe ser único y reconocible',
          newDesign: true
        }
      },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":"Eliminar Rol"},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"color":"#59a4f4","dark":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }